import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BackgroundFooter from "../images/salesforce/salesforce-bg-footer.png";
// import BackgroundFooter from "../images/salesforce/salesforce-Footer.jpg";

const SalesforceFooter = () => {
    return (
        <>
            <footer className="footer-salesforce" style={{ backgroundColor: "#000" }}>
                <div
                    className="salesforce-outer-div"
                    style={{
                        // background: `url(${BackgroundFooter})`,
                        background: `
                                  url(${BackgroundFooter}) right bottom / 53% no-repeat,
                                  linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                                  black`,
                        "@media (max-width: 768px)": {
                            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), black`,
                            backgroundSize: "100% auto",
                            backgroundPosition: "center bottom",
                        },
                        backgroundPosition: "right bottom",
                        backgroundSize: "53%",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div className="container">
                        <div className="col">
                            <div className="title">Services</div>
                            <ul className="first">
                                <li>Consulting</li>
                                <li>Salesforce Implementation</li>
                                <li>Development & Customization</li>
                                <li>Admin Support & Maintenance</li>
                                <li>Lightning Migration</li>
                            </ul>
                        </div>
                        <div className="col">
                            <div className="title">Industries</div>
                            <ul>
                                <li>Financial Services</li>
                                <li>High Tech Manufacturing</li>
                                <li>NonProfits</li>
                                <li>Education Cloud</li>
                                <li>Professional Services</li>
                            </ul>
                        </div>
                        <div className="col">
                            <div className="title">Expertise</div>
                            <ul>
                                <li>Sales Cloud</li>
                                <li>Services Cloud</li>
                                <li>Marketing Cloud</li>
                                <li>Pardot</li>
                                <li>Experience Cloud</li>
                                <li>Lightning Migration</li>
                                <li>Revenue Cloud</li>
                            </ul>
                        </div>
                    </div>
                    <div className="salesforce-image-grid container bottom">
                        <div className="salesforce-image-grid__socialmedia">
                            <div className="salesforce-image-grid__socialmedia-items">
                                <a
                                    href="https://www.linkedin.com/company/sedin-technologies/"
                                    target="_blank"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                    >
                                        <path
                                            d="M27 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V27C3 27.5304 3.21071 28.0391 3.58579 28.4142C3.96086 28.7893 4.46957 29 5 29H27C27.5304 29 28.0391 28.7893 28.4142 28.4142C28.7893 28.0391 29 27.5304 29 27V5C29 4.46957 28.7893 3.96086 28.4142 3.58579C28.0391 3.21071 27.5304 3 27 3ZM27 27H5V5H27V27ZM12 14V22C12 22.2652 11.8946 22.5196 11.7071 22.7071C11.5196 22.8946 11.2652 23 11 23C10.7348 23 10.4804 22.8946 10.2929 22.7071C10.1054 22.5196 10 22.2652 10 22V14C10 13.7348 10.1054 13.4804 10.2929 13.2929C10.4804 13.1054 10.7348 13 11 13C11.2652 13 11.5196 13.1054 11.7071 13.2929C11.8946 13.4804 12 13.7348 12 14ZM23 17.5V22C23 22.2652 22.8946 22.5196 22.7071 22.7071C22.5196 22.8946 22.2652 23 22 23C21.7348 23 21.4804 22.8946 21.2929 22.7071C21.1054 22.5196 21 22.2652 21 22V17.5C21 16.837 20.7366 16.2011 20.2678 15.7322C19.7989 15.2634 19.163 15 18.5 15C17.837 15 17.2011 15.2634 16.7322 15.7322C16.2634 16.2011 16 16.837 16 17.5V22C16 22.2652 15.8946 22.5196 15.7071 22.7071C15.5196 22.8946 15.2652 23 15 23C14.7348 23 14.4804 22.8946 14.2929 22.7071C14.1054 22.5196 14 22.2652 14 22V14C14.0012 13.7551 14.0923 13.5191 14.256 13.3369C14.4197 13.1546 14.6446 13.0388 14.888 13.0114C15.1314 12.9839 15.3764 13.0468 15.5765 13.188C15.7767 13.3292 15.918 13.539 15.9738 13.7775C16.6502 13.3186 17.4389 13.0526 18.2552 13.0081C19.0714 12.9637 19.8844 13.1424 20.6067 13.5251C21.329 13.9078 21.9335 14.48 22.3551 15.1803C22.7768 15.8806 22.9997 16.6825 23 17.5ZM12.5 10.5C12.5 10.7967 12.412 11.0867 12.2472 11.3334C12.0824 11.58 11.8481 11.7723 11.574 11.8858C11.2999 11.9994 10.9983 12.0291 10.7074 11.9712C10.4164 11.9133 10.1491 11.7704 9.93934 11.5607C9.72956 11.3509 9.5867 11.0836 9.52882 10.7926C9.47094 10.5017 9.50065 10.2001 9.61418 9.92597C9.72771 9.65189 9.91997 9.41762 10.1666 9.2528C10.4133 9.08797 10.7033 9 11 9C11.3978 9 11.7794 9.15804 12.0607 9.43934C12.342 9.72064 12.5 10.1022 12.5 10.5Z"
                                            fill="white"
                                        />
                                    </svg>
                                </a>
                                <a
                                    href="https://www.facebook.com/SedinTechnologies/"
                                    target="_blank"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                    >
                                        <path
                                            d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM17 26.9538V19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18C21 17.7348 20.8946 17.4804 20.7071 17.2929C20.5196 17.1054 20.2652 17 20 17H17V14C17 13.4696 17.2107 12.9609 17.5858 12.5858C17.9609 12.2107 18.4696 12 19 12H21C21.2652 12 21.5196 11.8946 21.7071 11.7071C21.8946 11.5196 22 11.2652 22 11C22 10.7348 21.8946 10.4804 21.7071 10.2929C21.5196 10.1054 21.2652 10 21 10H19C17.9391 10 16.9217 10.4214 16.1716 11.1716C15.4214 11.9217 15 12.9391 15 14V17H12C11.7348 17 11.4804 17.1054 11.2929 17.2929C11.1054 17.4804 11 17.7348 11 18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4804 18.8946 11.7348 19 12 19H15V26.9538C12.181 26.6964 9.56971 25.3622 7.7093 23.2287C5.8489 21.0952 4.8826 18.3266 5.0114 15.4988C5.1402 12.671 6.35419 10.0017 8.40085 8.04613C10.4475 6.09057 13.1693 4.9993 16 4.9993C18.8307 4.9993 21.5525 6.09057 23.5992 8.04613C25.6458 10.0017 26.8598 12.671 26.9886 15.4988C27.1174 18.3266 26.1511 21.0952 24.2907 23.2287C22.4303 25.3622 19.819 26.6964 17 26.9538Z"
                                            fill="white"
                                        />
                                    </svg>
                                </a>

                                <a href="https://twitter.com/SedinTech" hrefLang="_blank">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                    >
                                        <path
                                            d="M25.2454 6.1624L17.7672 14.0415M13.9709 18.0414L5.83255 26.6161M26.9813 26.8513L11.4621 6.03719C11.4446 6.01378 11.4172 6 11.3881 6L5.0926 6.00084C5.01642 6.00085 4.97295 6.08831 5.01866 6.1496L20.5418 26.9617C20.5592 26.9851 20.5866 26.9989 20.6157 26.9989L26.9074 27C26.9836 27 27.0271 26.9125 26.9813 26.8513Z"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <StaticImage
                                src="../images/salesforce/sedin-white-logo.svg"
                                alt="sedin-logo"
                                className="sedin-logo"
                                loading="lazy"
                            />
                        </div>
                        <div></div>
                        <div className="col">
                            <div className="salesforce-image-grid__partner">
                                <StaticImage
                                    src="../images/salesforce/partner-msp.png"
                                    alt="sedin-logo"
                                    className="sedin-logo"
                                    loading="lazy"
                                />
                                <StaticImage
                                    src="../images/salesforce/partner-ridge.png"
                                    alt="sedin-logo"
                                    className="sedin-logo"
                                    loading="lazy"
                                />
                                <StaticImage
                                    src="../images/salesforce/partner-pledge.png"
                                    alt="sedin-logo"
                                    className="sedin-logo"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <div className="container footer__bottom">
                    <div className="footer__bottom-text">
                        © {new Date().getFullYear()} &nbsp; Sedin Technologies Inc
                    </div>
                    <div></div>
                    <div className="footer__bottom-links">
                        <Link to="/terms-and-conditions/">Terms & Conditions</Link>
                        <Link to="/privacy-policy/">Privacy Policy</Link>
                        <Link to="/csr-policy/">CSR Policy</Link>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default SalesforceFooter;
